

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  text-decoration: none;
  color:#3c3a55
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* scroll bar styling */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #80808020;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #80808080;
}

/* add brand fonts */
@font-face {
  font-family: 'PoppinsSemiBold';
  src: local('PoppinsSemiBold'),
    url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'PoppinsMedium';
  src: local('PoppinsMedium'),
    url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'PoppinsRegular';
  src: local('PoppinsRegular'),
    url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}


@keyframes wave {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@media (max-width: 992px) {
  .calendar-page {
    min-height: calc(100vh - 180px);
  }
}

@media (min-width: 992px) {
  .calendar-page {
    height: calc(100vh - 180px);
    overflow-y: hidden;
  }
}

.max-width-container {
    max-width: 475.673px;
    margin-top: auto;
  }

.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}